/**
 * @generated SignedSource<<0ba4bd879eeebd79d3c7e3763cc1d3a7>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* eslint-disable */

'use strict';

var node = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "deviationType"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "formTypes"
  }
],
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "databaseId",
  "storageKey": null
},
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v3 = {
  "kind": "Variable",
  "name": "deviationType",
  "variableName": "deviationType"
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "deviationType",
  "storageKey": null
},
v5 = [
  {
    "alias": null,
    "args": null,
    "concreteType": "Organisation",
    "kind": "LinkedField",
    "name": "organisations",
    "plural": true,
    "selections": [
      (v1/*: any*/),
      (v2/*: any*/),
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "hasManual",
        "storageKey": null
      },
      {
        "alias": null,
        "args": [
          (v3/*: any*/)
        ],
        "concreteType": "DeviationTypesOrganisation",
        "kind": "LinkedField",
        "name": "deviationTypesOrganisation",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          (v1/*: any*/),
          (v4/*: any*/)
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": [
          (v3/*: any*/),
          {
            "kind": "Variable",
            "name": "formTypes",
            "variableName": "formTypes"
          }
        ],
        "concreteType": "DeviationForm",
        "kind": "LinkedField",
        "name": "deviationForms",
        "plural": true,
        "selections": [
          (v2/*: any*/),
          (v4/*: any*/),
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "formType",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "fields",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "DeviationTypesOrganisationFormQuery",
    "selections": (v5/*: any*/),
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "DeviationTypesOrganisationFormQuery",
    "selections": (v5/*: any*/)
  },
  "params": {
    "cacheID": "d23242db43d53aa55296e72b7f509f74",
    "id": null,
    "metadata": {},
    "name": "DeviationTypesOrganisationFormQuery",
    "operationKind": "query",
    "text": "query DeviationTypesOrganisationFormQuery(\n  $deviationType: String!\n  $formTypes: [String!]!\n) {\n  organisations {\n    databaseId\n    id\n    hasManual\n    deviationTypesOrganisation(deviationType: $deviationType) {\n      id\n      databaseId\n      deviationType\n    }\n    deviationForms(deviationType: $deviationType, formTypes: $formTypes) {\n      id\n      deviationType\n      formType\n      fields\n    }\n  }\n}\n"
  }
};
})();

node.hash = "e371456394a8706f43a5489572a3ae06";

module.exports = node;
