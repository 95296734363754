/**
 * @generated SignedSource<<198dd2564f547946db62ccd31860f5f5>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* eslint-disable */

'use strict';

var node = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "uuid"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "uuid",
    "variableName": "uuid"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "cause",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "comment",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "confidential",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "consequences",
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "consequencesClients",
  "storageKey": null
},
v7 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "consequencesEmployees",
  "storageKey": null
},
v8 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "consequencesInvolved",
  "storageKey": null
},
v9 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "consequencesOrganisation",
  "storageKey": null
},
v10 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "createdAt",
  "storageKey": null
},
v11 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "damageAmount",
  "storageKey": null
},
v12 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "datetimeOfDetection",
  "storageKey": null
},
v13 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "datetimeOfIncident",
  "storageKey": null
},
v14 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "description",
  "storageKey": null
},
v15 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "deviationType",
  "storageKey": null
},
v16 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "discussedWith",
  "storageKey": null
},
v17 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "encrypted",
  "storageKey": null
},
v18 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "endDate",
  "storageKey": null
},
v19 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "extraMeasures",
  "storageKey": null
},
v20 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "focusArea",
  "storageKey": null
},
v21 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "hasInaccessibleDeviation",
  "storageKey": null
},
v22 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "involvedOrganisations",
  "storageKey": null
},
v23 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v24 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "numberOfPersons",
  "storageKey": null
},
v25 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "reportedByEmail",
  "storageKey": null
},
v26 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "reportedByFirstname",
  "storageKey": null
},
v27 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "reportedByLastname",
  "storageKey": null
},
v28 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "reportedByPhone",
  "storageKey": null
},
v29 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "scope",
  "storageKey": null
},
v30 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "severity",
  "storageKey": null
},
v31 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "status",
  "storageKey": null
},
v32 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "takenActions",
  "storageKey": null
},
v33 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "urgency",
  "storageKey": null
},
v34 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v35 = [
  (v34/*: any*/),
  (v23/*: any*/)
],
v36 = {
  "alias": null,
  "args": null,
  "concreteType": "User",
  "kind": "LinkedField",
  "name": "assignedTo",
  "plural": false,
  "selections": (v35/*: any*/),
  "storageKey": null
},
v37 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "url",
  "storageKey": null
},
v38 = {
  "alias": null,
  "args": null,
  "concreteType": "AttachmentUrl",
  "kind": "LinkedField",
  "name": "attachmentUrls",
  "plural": true,
  "selections": [
    (v34/*: any*/),
    (v37/*: any*/)
  ],
  "storageKey": null
},
v39 = [
  (v23/*: any*/)
],
v40 = {
  "alias": null,
  "args": null,
  "concreteType": "DataOption",
  "kind": "LinkedField",
  "name": "dataOptions",
  "plural": true,
  "selections": (v35/*: any*/),
  "storageKey": null
},
v41 = {
  "alias": null,
  "args": null,
  "concreteType": "DataSubject",
  "kind": "LinkedField",
  "name": "dataSubjects",
  "plural": true,
  "selections": (v35/*: any*/),
  "storageKey": null
},
v42 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "number",
  "storageKey": null
},
v43 = [
  (v23/*: any*/),
  (v42/*: any*/)
],
v44 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "companyName",
  "storageKey": null
},
v45 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "hasManual",
  "storageKey": null
},
v46 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "uuid",
  "storageKey": null
},
v47 = [
  (v23/*: any*/),
  (v34/*: any*/)
],
v48 = [
  (v23/*: any*/),
  (v42/*: any*/),
  (v34/*: any*/)
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "AnonymousDeviationQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "Deviation",
        "kind": "LinkedField",
        "name": "anonymousDeviation",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          (v3/*: any*/),
          (v4/*: any*/),
          (v5/*: any*/),
          (v6/*: any*/),
          (v7/*: any*/),
          (v8/*: any*/),
          (v9/*: any*/),
          (v10/*: any*/),
          (v11/*: any*/),
          (v12/*: any*/),
          (v13/*: any*/),
          (v14/*: any*/),
          (v15/*: any*/),
          (v16/*: any*/),
          (v17/*: any*/),
          (v18/*: any*/),
          (v19/*: any*/),
          (v20/*: any*/),
          (v21/*: any*/),
          (v22/*: any*/),
          (v23/*: any*/),
          (v24/*: any*/),
          (v25/*: any*/),
          (v26/*: any*/),
          (v27/*: any*/),
          (v28/*: any*/),
          (v29/*: any*/),
          (v30/*: any*/),
          (v31/*: any*/),
          (v32/*: any*/),
          (v33/*: any*/),
          (v36/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "Attachment",
            "kind": "LinkedField",
            "name": "attachments",
            "plural": true,
            "selections": [
              (v34/*: any*/),
              {
                "args": null,
                "kind": "FragmentSpread",
                "name": "AttachmentLink_attachment"
              }
            ],
            "storageKey": null
          },
          (v38/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "BusinessUnit",
            "kind": "LinkedField",
            "name": "businessUnit",
            "plural": false,
            "selections": (v39/*: any*/),
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "User",
            "kind": "LinkedField",
            "name": "createdBy",
            "plural": false,
            "selections": (v39/*: any*/),
            "storageKey": null
          },
          (v40/*: any*/),
          (v41/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "Deviation",
            "kind": "LinkedField",
            "name": "deviation",
            "plural": false,
            "selections": (v39/*: any*/),
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "DeviationCategory",
            "kind": "LinkedField",
            "name": "deviationCategory",
            "plural": false,
            "selections": (v39/*: any*/),
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "ManualParagraph",
            "kind": "LinkedField",
            "name": "manualParagraphs",
            "plural": true,
            "selections": (v43/*: any*/),
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "NormParagraph",
            "kind": "LinkedField",
            "name": "normParagraphs",
            "plural": true,
            "selections": (v43/*: any*/),
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "Organisation",
            "kind": "LinkedField",
            "name": "organisation",
            "plural": false,
            "selections": [
              (v44/*: any*/),
              (v45/*: any*/),
              (v46/*: any*/)
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "DeviationCategory",
            "kind": "LinkedField",
            "name": "secondDeviationCategory",
            "plural": false,
            "selections": (v39/*: any*/),
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "DeviationCategory",
            "kind": "LinkedField",
            "name": "thirdDeviationCategory",
            "plural": false,
            "selections": (v39/*: any*/),
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "AnonymousDeviationQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "Deviation",
        "kind": "LinkedField",
        "name": "anonymousDeviation",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          (v3/*: any*/),
          (v4/*: any*/),
          (v5/*: any*/),
          (v6/*: any*/),
          (v7/*: any*/),
          (v8/*: any*/),
          (v9/*: any*/),
          (v10/*: any*/),
          (v11/*: any*/),
          (v12/*: any*/),
          (v13/*: any*/),
          (v14/*: any*/),
          (v15/*: any*/),
          (v16/*: any*/),
          (v17/*: any*/),
          (v18/*: any*/),
          (v19/*: any*/),
          (v20/*: any*/),
          (v21/*: any*/),
          (v22/*: any*/),
          (v23/*: any*/),
          (v24/*: any*/),
          (v25/*: any*/),
          (v26/*: any*/),
          (v27/*: any*/),
          (v28/*: any*/),
          (v29/*: any*/),
          (v30/*: any*/),
          (v31/*: any*/),
          (v32/*: any*/),
          (v33/*: any*/),
          (v36/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "Attachment",
            "kind": "LinkedField",
            "name": "attachments",
            "plural": true,
            "selections": [
              (v34/*: any*/),
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "filename",
                "storageKey": null
              },
              (v37/*: any*/)
            ],
            "storageKey": null
          },
          (v38/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "BusinessUnit",
            "kind": "LinkedField",
            "name": "businessUnit",
            "plural": false,
            "selections": (v47/*: any*/),
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "User",
            "kind": "LinkedField",
            "name": "createdBy",
            "plural": false,
            "selections": (v47/*: any*/),
            "storageKey": null
          },
          (v40/*: any*/),
          (v41/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "Deviation",
            "kind": "LinkedField",
            "name": "deviation",
            "plural": false,
            "selections": (v47/*: any*/),
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "DeviationCategory",
            "kind": "LinkedField",
            "name": "deviationCategory",
            "plural": false,
            "selections": (v47/*: any*/),
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "ManualParagraph",
            "kind": "LinkedField",
            "name": "manualParagraphs",
            "plural": true,
            "selections": (v48/*: any*/),
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "NormParagraph",
            "kind": "LinkedField",
            "name": "normParagraphs",
            "plural": true,
            "selections": (v48/*: any*/),
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "Organisation",
            "kind": "LinkedField",
            "name": "organisation",
            "plural": false,
            "selections": [
              (v44/*: any*/),
              (v45/*: any*/),
              (v46/*: any*/),
              (v34/*: any*/)
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "DeviationCategory",
            "kind": "LinkedField",
            "name": "secondDeviationCategory",
            "plural": false,
            "selections": (v47/*: any*/),
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "DeviationCategory",
            "kind": "LinkedField",
            "name": "thirdDeviationCategory",
            "plural": false,
            "selections": (v47/*: any*/),
            "storageKey": null
          },
          (v34/*: any*/)
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "e40bf81fa2ca1cde4fcb4b0d50c7767a",
    "id": null,
    "metadata": {},
    "name": "AnonymousDeviationQuery",
    "operationKind": "query",
    "text": "query AnonymousDeviationQuery(\n  $uuid: String!\n) {\n  anonymousDeviation(uuid: $uuid) {\n    cause\n    comment\n    confidential\n    consequences\n    consequencesClients\n    consequencesEmployees\n    consequencesInvolved\n    consequencesOrganisation\n    createdAt\n    damageAmount\n    datetimeOfDetection\n    datetimeOfIncident\n    description\n    deviationType\n    discussedWith\n    encrypted\n    endDate\n    extraMeasures\n    focusArea\n    hasInaccessibleDeviation\n    involvedOrganisations\n    name\n    numberOfPersons\n    reportedByEmail\n    reportedByFirstname\n    reportedByLastname\n    reportedByPhone\n    scope\n    severity\n    status\n    takenActions\n    urgency\n    assignedTo {\n      id\n      name\n    }\n    attachments {\n      id\n      ...AttachmentLink_attachment\n    }\n    attachmentUrls {\n      id\n      url\n    }\n    businessUnit {\n      name\n      id\n    }\n    createdBy {\n      name\n      id\n    }\n    dataOptions {\n      id\n      name\n    }\n    dataSubjects {\n      id\n      name\n    }\n    deviation {\n      name\n      id\n    }\n    deviationCategory {\n      name\n      id\n    }\n    manualParagraphs {\n      name\n      number\n      id\n    }\n    normParagraphs {\n      name\n      number\n      id\n    }\n    organisation {\n      companyName\n      hasManual\n      uuid\n      id\n    }\n    secondDeviationCategory {\n      name\n      id\n    }\n    thirdDeviationCategory {\n      name\n      id\n    }\n    id\n  }\n}\n\nfragment AttachmentLink_attachment on Attachment {\n  filename\n  url\n}\n"
  }
};
})();

node.hash = "9c5fe45438866b5cb3dc3f6cefb9fe0f";

module.exports = node;
